import { Button } from "easyship-components";
interface SuccessPageProps {
  platformName: string;
  onValidated: () => void;
  isLoggedIn: boolean;
}

export const SuccessPage = ({ platformName, onValidated, isLoggedIn }: SuccessPageProps) => {
  return (
    <div className="flex flex-col gap-y-5 text-center text-ink-900 mt-6">
      <p className="text-xl">{isLoggedIn ? "You’re all set!" : "You’re signed up!"}</p>
      <img src="/images/success.svg" alt="Account Created" />
      <p>Your Easyship account has successfully been {isLoggedIn ? "updated with your details" : "created"}.</p>
      <p>Now let’s link it with {platformName || "Your Platform"} so you can start shipping straight away!</p>
      <Button
        color="primary"
        onClick={() => onValidated()}
        className="w-full"
      >
        Continue
      </Button>
    </div>
  );
};
