import { Elements } from '@stripe/react-stripe-js';
import { useFormContext } from 'react-hook-form';

// Models
import Loading from '@/components/Loading';
import { FormData } from '@/core/models/User';
// Hooks
import useLoadStripe from '@/hooks/useLoadStripe';
import useValidatePaymentMutation from '@/hooks/validation/useValidatePaymentMutation';

// Components
import { StripeCardComponent } from './StripeCardComponent';
import { AxiosError } from 'axios';
import { toastError } from '@/components/Toastify';

interface PaymentPageProps {
  countryId: number;
  onValidated: () => void;
  stepId: string;
}

export const PaymentPage = ({ countryId, onValidated, stepId }: PaymentPageProps) => {
  const { data: stripe, isLoading } = useLoadStripe(countryId);
  const { mutateAsync: validationMutateAsync } = useValidatePaymentMutation();
  const { register, setValue } = useFormContext<FormData>();
  register('payment.token');
  register('payment.countryId');
  setValue('payment.id', stepId);

  const verifyStripeDetails = async (token: string) => {
    if (stripe) {
      const data = { token, countryId, id: stepId };
      const stripeToken = await validationMutateAsync(
        { stripe, data },
        {
          onError: (err) => {
            if (err.response) {
              err?.response?.data.errors.forEach((error: string) => {
                toastError(error);
              });
            } else {
              toastError();
            }
          },
        },
      );
      if (stripeToken) {
        setValue('payment.token', stripeToken);
        setValue('payment.countryId', countryId);
        onValidated();
      }
    }
  };

  return (
    <div className="mt-6">
      {isLoading && <Loading />}
      {stripe && (
        <Elements stripe={stripe}>
          <p className="mb-6 text-center text-ink-900">
            We use your payment information to set up a default payment method to pay for your shipping labels.
          </p>
          <div className="grid gap-5">
            <StripeCardComponent handleToken={verifyStripeDetails} />
          </div>
        </Elements>
      )}
    </div>
  );
};
